.card {
    position: relative;
    height: 33vh;
    width: 100%;
    
    min-width:180px;
    padding: 1rem;
    border-radius: 16px;
    
    box-shadow: -1rem 0 3rem #000;
    display: flex;
    flex-direction: column;
    color: white;
    scroll-snap-align: start;
    clear: both;

    
    background-color: rgba(255,255,255,0.02);
    border: 1px solid rgba(255,255,255,0.1);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.intro-text h1 {
  color:#B1A6C9;
  padding-bottom: 0px;
}

.intro-text {
    z-index: 1;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}

.intro-name {
    color: #C6FCED;
    font-size: 25px;
    padding: 0;
}

.intro-outro {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
}

.card-container {
    display: flex;
    width: 80%;
    max-width: 500px;
    margin: 50px;
}

.card p, .card h3{
    z-index: 1;
    margin: 0;
}

.program-text {
    padding-top: 10px;
}


.card::before {  
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
    position: absolute;
    z-index: 2;
    opacity: 1;
} 

.front-intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100vh;
    justify-items: center;
    align-items: center;
    grid-column: 1;
    
  }
  
  @media (max-width: 560px){
    .front-intro {
      grid-template-columns: 1fr; 
    }
  }
  
  .middle {
    position: absolute;
    top: 90vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  
  .mouse{
    width: 40px;
    height: 70px;
    border: 4px solid #333;
    border-radius: 30px;
    z-index: 3;
  }
  .mouse::before{
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50px;
    background: #333;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 1;
    animation: mouse 1.5s infinite;
  }

  @media (max-width: 500px){

      .mouse {
        width: 30px;
        height: 55px;
      }
  }
  
  @keyframes mouse {
    from{
      opacity: 1;
      top: 15px;
  
    }
    to{
      opacity: 0;
      top: 30px;
    }
  }