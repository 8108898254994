.projects-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
}

.projects-container h1{
    color:#B1A6C9;
    text-align: center;
    margin: 50px;
    min-width: 250px;
}

.projects {
    display: grid;
    position: relative;
    margin-top: 0;
    margin-left: 17%;
    margin-right: 17%;
    margin-bottom: 10%;
    max-width: 1000px;
    justify-items:center;
    align-content: center;
    row-gap: 10%;
    column-gap: 5%;
    grid-template-columns: repeat(3, minmax(130px, 250px));
    grid-template-rows: repeat(2, minmax(280px, 100%));
    flex-wrap: wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}

.fade-in {
    transform: translateY(50%);
    filter: blur(5px);
    opacity: 0;
    transition: all 1s ease-out;

}

.fade-in-appear {
    transform: translateY(0%);
    opacity: 1;
    filter:blur(0px);
    transition: opacity 1.0s ease-out, transform 1.0s ease-out 500ms, filter 1.0s ease-out;
}

.fade-in:nth-child(1),
.fade-in:nth-child(4){
    transform: translateX(-50%);
}

.fade-in:nth-child(1),
.fade-in:nth-child(4){
    transform: translateX(-50%);
}

.fade-in:nth-child(3),
.fade-in:nth-child(6){
    transform: translateX(50%);
}

.fade-in:nth-child(2){
    transform: translateY(-50%);
}

.fade-in:nth-child(5){
    transform: translateY(50%);
}

.fade-in-appear:nth-child(4),
.fade-in-appear:nth-child(5),
.fade-in-appear:nth-child(6){
    transition-delay: 500ms;
}


.fade-in-mouse {
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.fade-in-mouse-appear{
    opacity: 1;
    transition: all 1s ease-in-out;
}



@media (max-width: 839px){
    .projects {
        grid-template-columns: minmax(250px, 100%);
        grid-template-rows: repeat(6, minmax(250px, 100%));
        row-gap: 10px;
    }
}

@media (min-width: 840px) and (max-width: 1050px){
    .projects {
            grid-template-columns: repeat(3, minmax(130px, 250px));
            grid-template-rows: repeat(2, minmax(340px, 100%));
    }
}

.projects p{
    font-size: 14px;
    text-align:left;
    margin: 4px;
    margin-left:11px;
    color: #ffffff; 
}

.project-git-link{
    font-size: 16px;
    font-weight:500;
    display: flex;
    justify-content: center;
    margin:10px;
}

.projects-item-1 { grid-column: 1; grid-row: 1}
.projects-item-2 { grid-column: 2; grid-row: 1}
.projects-item-3 { grid-column: 3; grid-row: 1}
.projects-item-4 {grid-column: 1; grid-row: 2}
.projects-item-5 {grid-column: 2; grid-row: 2}
.projects-item-6 {grid-column: 3; grid-row: 2}


.project-card {
    position: relative;
    display: flex; 
    overflow:hidden;
    border-radius: 8px;
    
}

.hover-card:hover{
    transition: 0.5s;
    -webkit-box-shadow: 0px 6px 11px 0px #000000; 
    box-shadow: 0px 6px 11px 0px #000000;

}

.hover-card {
    transition: 1s;
    -webkit-box-shadow: 0px 6px 11px 0px #0000004e; 
    box-shadow: 0px 6px 11px 0px #0000004e;
    border: 1px solid rgb(32, 32, 32);
}


.project-card::before {
    content: '';
    position: absolute;
    left: 50%;
    top: -100%;
    transform: translate(-50%, -50%);
    
    width: 100px;
    height: 800px;
    background: linear-gradient(#d468ff, #00d471);
    animation: animate 4s linear infinite;
    opacity: 0;
    transition: opacity 0.5s;

    
}

.project-card:hover::before {
    opacity: 1;
}

.project-text{
   cursor:pointer; 
}

@media (max-width: 500px){


    .hover-card {
        -webkit-box-shadow: 0px 6px 11px 0px #000000; 
        box-shadow: 0px 6px 11px 0px #000000;
    }
}

.project-card::after {
    content: '';
    position: absolute;
    inset: 1px;
    background: #17141d; 
    border-radius: 8px; 
}

.project-content {
    position: relative;
    z-index: 5;
    height: 100%;
    cursor:default;
}

@keyframes animate {
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}

.top-card {
    display: grid;
    grid-template-columns: minmax(100%, 100%) minmax(100%, 100%);
    align-content: center;
    text-align: left;
    margin-left: 10px;
    color: #C6FCED;
}

.project-date {
    display:flex;
    position: relative;
    justify-content: right;
    margin: 10px;
    font-weight: 500;
    color:#a19ea8;
}

.divider {
    height: 100px;
}







  



