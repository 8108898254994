body {
    font-family: 'Source Code Pro', monospace;
    font-weight: 200;
    font-size: 14px;
    margin: 0px; 
    overflow-x: hidden;
    max-width: 100%;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration-line: none;
    color: white;
}

:target:before {
    content: "";
    display: block;
}

p, a, h1, h2, h3 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

body, html, #root{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #17141d;
    
  }
  
  .canvas {
    background-color: #17141d;
    height: 100vh;
    z-index: 0;
  }
  
  .front {
    position: absolute;
    left: 0;
    right: 0;
  }
  
  .front * {
    z-index: 2;
  }
