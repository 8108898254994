
.contact-container {
  margin-top: 20vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.contact-container h1 {
  color: white;
  color:#B1A6C9;
  margin: 50px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}

 .contact-section {
  font-size: 20px;
  font-weight: 400;
  color: white;

  display: grid;
  align-content:center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  
}

@media(max-width: 890px ){
  .contact-section {
    grid-template-columns: 1fr;
    justify-items: left;
  }
} 

 .contact-section p {
  text-align: left;
  
}

@media (max-width: 530px){
  .contact-container {
    font-size: 20px;
    text-align: center;
  }
} 
