.projects-video-container {  
  display: grid;
  width: 197vw;
  grid-template-columns: 98vw 98vw;
  transform: translateX(-100vw);
  opacity: 1;
  transition: all 1.5s ease-in-out;
  overflow-x: hidden;
  
}

.scroll-to-video{
  transition: all 1.5s ease-in-out;
  opacity: 1;
  transform: translateX(0vw);
}

.projects-container {
  opacity: 1;
  filter: blur(0px);
  transition: transform ease-in 2s, opacity 2s, filter 2s;
}

.show-vid {
  overflow:hidden;
  filter: blur(5px);
  opacity: 0;
  transition: transform ease-in 2s, opacity 2s, filter 2s;
}


.video-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-content: center;
  justify-items: center;
  overflow:hidden;
  height: 100vh;
  opacity: 0;
  transition: opacity 1s;
}


.video-container-show-vid{
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-content: center;
  justify-items: center;
  overflow:hidden;
  height: 100vh;
  opacity: 1;
  transition: opacity 3s;
}


.videos-show-vid iframe{
  border: solid 1px black;
  -webkit-box-shadow: 0px 6px 11px 0px #000000; 
  box-shadow: 0px 6px 11px 0px #000000;
  opacity: 1;
  transition: opacity 2s;
  
  width: 50vw;
  height: 30vw;
}

.videos iframe {
  transition: opacity 2s;
  opacity: 0;
  width: 50vw;
  height: 30vw;
}


.video-right-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  max-width: 300px;
  margin-right: 5%;
}


.video-text-container h1 {
  text-align: left;
  margin-left: 10px;
  color: #C6FCED
}

.video-text-container{
  position: relative;
  display: flex; 
  flex-direction: column;
  overflow:hidden;
  height: 80%;
  border-radius: 8px;
  transition: box-shadow 2s, -webkit-box-shadow 2s;
}

@media (max-width: 700px){
  .videos-show-vid iframe {
    width: 80vw;
    height: 60vw;
  }

  .video-container-show-vid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .video-right-section {
    max-width: 500px;
  }
}


.video-text-container::before {
  content: '';
  position: absolute;
  top: -50vh;
  transform: translate(-50%, -50%);
  
  width: 100px;
  height: 800px;
  background: linear-gradient(#d468ff, #00d471);
  animation: animate 4s linear infinite;
  opacity: 0;
  transition: opacity 0.5s;
}

.video-text-container:hover::before {
  opacity: 1;
  cursor: pointer;
}

.video-text-container::after {
  content: '';
  position: absolute;
  inset: 1px;
  background: #17141d;
  border-radius: 30px; 
}

.video-text{
  font-size: 14px;
  text-align: justify;
  margin: 10px;
  z-index: 6;
  color: #ffffff; 
}

.video-text-container:hover{
  transition: 0.5s;
  -webkit-box-shadow: 0px 6px 11px 0px #000000; 
  box-shadow: 0px 6px 11px 0px #000000;
}

.return-projects {
  margin-top: 50px;
  cursor: pointer;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 6px 11px 0px #000000; 
  box-shadow: 0px 6px 11px 0px #000000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px gray;
  transition: all 0.2s;
}

.return-projects:hover {
  -webkit-box-shadow: 0px 15px 15px 0px #000000; 
  box-shadow: 0px 15px 15px 0px #000000;
  border: solid 1px white;
}


@media (max-width: 500px){
  .projects-video-container {
    display: flex;
    flex-direction:column-reverse;
    width: 100%;
    height: 100%;
    transform: translateX(0vw);
    transition: transform 0s ease-in-out, opacity 0s ease-in-out;
  }

  .scroll-to-video {
    flex-direction: column;
    transition: all 0s ease-in-out;
  }
  
  .video-container {
    height: 0px;
  }

  .show-vid {
    height: 0px;
  }

  .projects-container {
    opacity: 1;
    filter: blur(0px);
    transition: transform ease-in 0s, opacity 0s, filter 0s;
  }
}

