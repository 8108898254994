.header-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  font-size: 20px;
  font-weight: 700;
  
  color: rgb(250, 250, 250);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;

}

.mobile-header-button {
  position: fixed;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 10;
}

.mobile-header-menu {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;

      -webkit-animation: fadein 0.2s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.2s;
        /* Firefox < 16 */
        -ms-animation: fadein 0.2s;
        /* Internet Explorer */
        -o-animation: fadein 0.2s;
        /* Opera < 12.1 */
        animation: fadein 0.2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.header-menu {
  display: flex;
  align-items: center;
  z-index: 3;
}

.header-menu>* {
  padding: 10px;
}

.header-menu div {
  text-transform: uppercase;
  cursor: pointer;


}

.header-menu div:hover {
  color: rgb(66, 75, 93);
}

.header-menu a {
  transition: color 0.25s;
}

.header-menu a:hover {
  color: lightslategray;
}

.header-social-media {
  display: flex;
  align-items: center;
}

.header-social-media>* {
  padding: 5px;
}

.header-social-media div {
  cursor: pointer;
  transition: transform 0.2s;
}

.header-social-media div:hover {
  transform: scale(1.2);
}