.about-container {
  height: 60vh;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10%;
  margin-left: 10%;
  min-width: 300px;

}

@media (max-width: 870px ){
  .about-container {
    height: 110vh;
  }
}

@media (max-width: 500px ){
  .about-container {
    height: 130vh;
  }
}

.about-container h1 {
  display: flex;
  color: white;
  color:#B1A6C9;
  margin: 50px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}

.about-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.about-right-section p {
  color: white;
  margin-right: 50px;
  font-size: 17px;
  font-weight: 100;
  text-align: left;

}

.about-right-section h3 {
  font-size: 25px;
  font-weight: 500;
  color: #C6FCED;
}

.about-right-section h2 {
  font-size: 20px;
  color: white;
  font-weight: 400;
}

.about-left-section {
  display: flex;
  
  align-items: center;
  justify-content: center;
}


.picture-carousel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 900px){
  .picture-carousel {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media (max-width: 700px){
  .about-section {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}

.picture-carousel img {
  height: 50px;
}

.logo {
  opacity: 0;
  filter:blur(5px);
  transform: translateX(-100%);
  transition: opacity 0.5s, filter 0.5s, transform 0.5s;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.02);
  border: 1px solid rgba(255,255,255,0.1);
  -webkit-box-shadow: 0px 6px 11px 0px #000000; 
  box-shadow: 0px 6px 11px 0px #000000;

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}

.picture-carousel .appear {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0%);
}

.logo:nth-child(2){
  transition-delay: 100ms;
}

.logo:nth-child(3){
  transition-delay: 200ms;
}

.logo:nth-child(4){
  transition-delay: 300ms;
}

.logo:nth-child(5){
  transition-delay: 400ms;
}

.logo:nth-child(6){
  transition-delay: 500ms;
}

.logo:nth-child(7){
  transition-delay: 600ms;
}

.logo:nth-child(8){
  transition-delay: 700ms;
}

.link-to-audio {
  transition: color 0.25s;
  font-size: 18px;
}

.link-to-audio:hover{
  color: lightslategray;
}
